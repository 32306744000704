export const buzzword_grammar = {
  "origin": "#sentence#",
  "sentence" : [
    "Our #buzzjective# #buzznoun# #buzzverb.s# #buzzjective#, #buzzjective# #buzznoun.s#.",
    "It's critical that we use #buzzjective# #buzznoun.s# in order to #buzzverb# the types of #buzznoun.s# our customers #buzzverb#.",
    "We aim to #buzzverb# our #buzznoun.s# by #buzzdverb# #buzzverb#ing our #buzznoun.s#.",
    "Engagement of our #buzznoun.s# results in #buzzverb#ing our client's #buzznoun.s#."
  ],
  "suffix": [
    "",
    "ing",
    "ed"
  ],
  "buzzdverb": [
    "dynamically",
    "ethically",
    "reliably",
    "deeply",
    "profoundly",
    "expediently",
    "bigly"
  ],
  "buzzjective": [
    "seamless",
    "unparalleled",
    "immersive",
    "proactive",
    "mission critical",
    "innovative",
    "scalable",
    "personalized",
    "integrated",
    "cross-platform",
    "targeted",
    "segmented",
    "optimal",
    "sustainable",
    "streamline",
    "omnichannel",
    "bleeding edge",
    "predictive",
    "modular",
    "end-to-end",
    "enterprise-level",
    "real-time",
    "on-demand",
    "restful",
    "distributed",
    "functional",
    "best-in-class",
    "legacy",
    "non-linear",
    "concurrent",
    "agile"
  ],
  "buzzverb": [
    "engineer",
    "reuse",
    "adopt",
    "enable",
    "engage",
    "drive",
    "execute",
    "enhance",
    "spin-up",
    "touch-base",
    "datafy",
    "expect",
    "starburst",
    "synergize"
  ],
  "buzznoun": [
    "mobile-first design",
    "crypto",
    "cryptocurrency",
    "machine learning",
    "bloatware",
    "KPI",
    "transparency",
    "microservice",
    "Web3",
    "webassembly",
    "data science",
    "analytics",
    "return on investment",
    "industry leader",
    "best practice",
    "agile workflow",
    "user experience",
    "organic growth",
    "big data",
    "team player",
    "emerging market",
    "scalability",
    "core asset",
    "front-end developer",
    "API",
    "framework",
    "cloud computing",
    "millenial",
    "responsive web design",
    "architecture",
    "information super-highway",
    "world wide web",
    "hyperlink",
    "development lifecycle",
  ],
    "buzznoun-plural": [
        "cryptocurrencies",
        "machine-learnings",
        "bloatwares",
        "KPIs",
        "transparencies",
        "microservices",
        "Web3s",
        "web assemblies",
        "data sciences",
        "analytics",
        "return on investments",
        "industry leaders",
        "best practices",
        "agile workflows",
        "user experiences",
        "organic growths",
        "big datas",
        "team players",
        "emerging markets",
        "scalabilities",
        "core assets",
        "fullstack developers",
        "APIs",
        "frameworks",
        "cloud computings",
        "millenials",
        "responsive web designs",
        "architectures",
        "information super-highways",
        "world wide webs",
        "hyperlinks",
        "development lifecycles"
    ]

};
