import React from 'react';
import './App.css';
import { BuzzwordHomePage } from "./pages/Buzzword-HomePage";

function App() {
  return (
    <BuzzwordHomePage />
  );
}

export default App;
